import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { SizedBox } from "./SizedBox";

const NavWrapper = styled.div`
text-align: center;
align-items: center;
width: "100%";
background-color: rgba(0,0,0);
position:"absolute";
top: "0px";
`
const MobileNavWrapper = styled.div`
text-align: center;
align-items: center;
width: "100%";
background-color: rgba(0,0,0);
position:"absolute";
top: "0px";
padding: 1px;
`

const GolferzEmblem = styled.img`
  width: 70px;
  justify-content: right;
  display: inline-block;
  
`;

const MobileEmblem = styled.img`
  width: 48px;
  height: 48px;
  justify-content: right;
  display: inline-block;
  padding-top:2%;
  margin: auto;
`;

const InnerWrapper = styled.nav`
  width: 50%;
  vertical-align: middle;
  margin: auto;
  font-size: 1.5em;

`;

const MobileInnerWrapper = styled.nav`
  width: 95%;
  vertical-align: middle;
`;

const TitleWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: white;
  vertical-align: middle;
  display: inline-block;
`;

const Links = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  vertical-align: middle;
  display: inline-block;
  font-size: "24px";
  color: black;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding-top:2%;
`;

const MobileLinks = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  vertical-align: middle;
  display: inline-block;
  font-size: "1px";
  color: black;
  text-align: center;
  margin: auto;
  padding-top:3%;
`;

const WordWrapper = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  margin: auto;
`

const MobileWordWrapper = styled.div`
  font-size: "4px";
  color: black;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding-top:3%;
`

export default function NavBar({collab, url})
{
    return(
        <>

    <NavWrapper className="d-flex justify-content-center">
        <InnerWrapper className="d-flex justify-content-center">
                    <TitleWrapper href={"https://headcoverdao.com"} className="d-flex">
                        <WordWrapper className="d-flex justify-content-center">
                            <h1>headcover DAO</h1>
                        </WordWrapper>
                    </TitleWrapper>
        </InnerWrapper>
    </NavWrapper>
    </>
    )
}