import logo from './logo.svg';
import './App.css';
import AirDrop from './pages/AirDrop';

function App() {
  return (
    <div className="App">
      <AirDrop />
    </div>
  );
}

export default App;
