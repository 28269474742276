import {Col, Nav, Row} from "react-bootstrap";
import MediumButton from "../components/MediumButton";
import { SizedBox } from "../components/SizedBox";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import { BrowserView, MobileView } from "react-device-detect";
import { Client } from "@notionhq/client"
const { createClient }= require('@supabase/supabase-js');

const contractAddress = "0xe20Ef7236F061c7ed11366EDAC8D37E1861e6dD3";

//not really great opsec, but no one is really going to be able to compromise the db when we are only really using it for one purpose
const alchemyKey = "_iTnnLNw8EhUEvdNP2xyK4BrtlDUW2NL";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imxpbmljb3hmeWVnZ2xwZ3ZrZ2NvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzUxODIyMTgsImV4cCI6MTk5MDc1ODIxOH0.EOzICTvw9XtJ-kEKbeTcjFH0DFrVxlioQYUr60Oggc8";

//create supabase client
const supabase = createClient("https://linicoxfyegglpgvkgco.supabase.co", supabaseKey);

//styled components
const Title = styled.h1`
font-size: 3.5em;
`

const CoverBox = styled.div`
  width: 70%;
  background-color: rgba(0, 0, 0, 0.9);
  outline: white solid 1px;
  border-radius: 20px;
  margin:auto;
  padding: 20px;
`;


export default function AirDrop() {
    const [currentAccount, setCurrentAccount] = useState(null);
    const [banner, setBanner] = useState("Connect your Metamask wallet!");
    const [nftList, setNftList] = useState([]);
    const [nfts, setNfts] = useState([]);
    const [validTokens, setValidTokens] = useState([]);
    const [type, setType] = useState(null);
    const isClaimable = false;

    async function checkDB(){
      if(validTokens.length != 0){
        let tokens = validTokens;
        const { data, error } = await supabase
      .from('Doubloons')
      .select(`id, type`)
      //console.log(data, error);
    
      data.forEach(datum => {
        console.log(datum.id);
        console.log(validTokens);
      if(validTokens.includes(datum.id))
      {
        console.log("checkit");
        if(datum.type != null)
        {
            console.log("used token " + datum.id);
            if(tokens.length == 1)
            {
              setValidTokens([]);
            }
            else{
            tokens.splice(tokens.indexOf(datum.id), 1);
            setValidTokens(tokens);}
        }
      }
      })}
    }

    async function postDB(cover)
    {
      if(cover != null){
        setType(cover);
        const { error } = await supabase
        .from('Doubloons')
        .insert({ id: validTokens[0], ethAddress: currentAccount, type: cover})
          console.log(error);
      }
    }

    const checkWalletConnection = async () => {
        const { ethereum } = window;
        if(!ethereum) {
            console.log("You need to have Metamask installed!");
            return;
        } else {
            console.log("Metamask is installed.");
        }

        const accounts = await ethereum.request({method: "eth_accounts" });

        if(accounts.length > 0) {
            const account = accounts[0];
            setCurrentAccount(account);
            setBanner(currentAccount);
        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if(!ethereum) {
            console.log("You need to have Metamask installed!");
        } 

        try {
            const accounts = await ethereum.request({method: "eth_requestAccounts"});
            setCurrentAccount(accounts[0]);
            await getNFTs();
        } catch (e) {
            console.log(e);
        }
    }


    const getNFTs = async () => {
        try{
            const options = {method: 'GET', headers: {accept: 'application/json'}};

            const response = await fetch('https://eth-mainnet.g.alchemy.com/nft/v2/' + alchemyKey + '/getNFTs?owner=' + currentAccount + '&pageSize=100&contractAddresses[]=0xe20Ef7236F061c7ed11366EDAC8D37E1861e6dD3&withMetadata=false', options)
            const json = await response.json();

            let nftIds = [];
            json.ownedNfts.forEach(el => {
                nftIds.push(el.id.tokenId)
                console.log(el.id.tokenId)
            });
            
            console.log(await nftIds);
            
            //validate tokens with Notion TO DO
            setValidTokens(nftIds);
            console.log(validTokens);
      } catch (e) {
        console.log(e);
    }

  }

  const checkNFTs = async (nfts) => {
            console.log(nfts);
            let nftIds = [];
            nfts.forEach(el => {
                nftIds.push(el.id.tokenId)
                console.log(el.id.tokenId)
            });
            
            console.log(nftIds);
            
            //validate tokens with Notion TO DO
            setValidTokens(nftIds);
            console.log(validTokens);
            checkDB();

    }

    useEffect(() => {
      checkDB()
    }, [validTokens])

    //useEffect(() => {
    //  postDB()
    //}, [type])
    
    useEffect(() => {
        checkWalletConnection();
        if(currentAccount)
        {
            console.log(currentAccount)
            getNFTs();
        }
    }, [currentAccount])


        return(
                <>
                <BrowserView>
                    <div style={{width: "70%", backgroundColor:"rgba(0,0,0,0.9", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "15%", minHeight: "100vh"}}>
                    <div style={{padding: "25px"}}>
                    <SizedBox height={"50px"} />
                        <Col>
                        {type == null ? <>
                        {isClaimable ? <>
                            <Row>
                                <Title>Headcover Airdrop Claim</Title>
                                <h4>Welcome to our first claim period for Genesis Headcover NFTs.  These NFTs will be used to exchange for the inaugural Kraken themed headcovers that come free with your Doubloon mint.* </h4>
                            </Row>
                            
                            <SizedBox height={"100px"} />
                            <Col>

                            <CoverBox>
                            <div style={{ padding: "5px", width: "80%", height: "15%", margin: "auto"}}>
                            Let's get started!
                            <p>connect your wallet to claim a cover</p>
                                        {currentAccount ? 
                                        <>
                                            <div>{currentAccount}</div>
                                            {validTokens.length > 0 ?
                                                <p>You qualify for a headcover</p>
                                                :
                                                <>
                                                    <p>You do not have any valid doubloons!</p>
                                                    <a href="https://headcoverdao.com/mint"><MediumButton text={"Mint a doubloon"} width={"50%"} /></a>
                                                </>
                                            }
                                        </>
                                        :    
                                            <MediumButton text={"Connect Wallet"} onClick={connectWalletHandler} width={"50%"} />}
                                    </div>
                            </CoverBox>
                                    
                                </Col>
                                <SizedBox height={"100px"} />

                                <div style={{width: "100%", margin: "auto"}}>
                                <Col>
                                    <Row>
                                    <Col><CoverBox><Row><img src="/images/Blade.webp"/></Row><Row><MediumButton text={"Select Blade"} disabled={validTokens.length == 0} onClick={() => {postDB("Blade");}} width={"50%"}/></Row></CoverBox></Col>
                                    <Col><CoverBox><Row><img src="/images/Mallet.webp"/></Row><Row><MediumButton text={"Select Mallet"} disabled={validTokens.length == 0} onClick={() => {postDB("Mallet");}} width={"50%"}/></Row></CoverBox></Col>
                                    </Row>
                                    </Col>
                                </div>
                                </> : <>
                                <Row>
                                <Title>Headcover Airdrop Claim</Title>
                                <h4>HeadcoverDAO is not doing a claim for an airdrop at this time</h4>
                                <a href="https://discord.gg/RjZxMywdJM"> <MediumButton text={"Check Discord for updates"} width={"50%"}/></a>
                            </Row>
                                </>}
                            </> : <>
                            <Title>Headcover Claimed!</Title>
                            <SizedBox height="75px"/>
                            <CoverBox>
                                <h1>Thank you</h1>
                                <p>{currentAccount}, your {type} headcover will be airdropped soon!</p>
                                <img src={"/images/" + type + ".webp"}/>
                            </CoverBox></>}
                        </Col>
                    </div>
                </div>
            </BrowserView>


            <MobileView>
                <div style={{width: "95%", backgroundColor:"rgba(0,0,0,0.9", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "2.5%", minHeight: "100vh"}}>
                    <div style={{padding: "5px"}}>
                    <SizedBox height={"50px"} />
                        <Col>
                        {type == null ? <>
                        {isClaimable ? <>
                            <Row>
                                <Title>Headcover Airdrop Claim</Title>
                                <h4>Welcome to our first claim period for Genesis Headcover NFTs.  These NFTs will be used to exchange for the inaugural Kraken themed headcovers that come free with your Doubloon mint.* </h4>
                            </Row>
                            
                            <SizedBox height={"100px"} />
                            <Col>

                            <CoverBox>
                            <div style={{ padding: "5px", width: "95%", height: "15%", margin: "auto"}}>
                            Let's get started!
                            <p>connect your wallet to claim a cover</p>
                                        {currentAccount ? 
                                        <>
                                            <div><p>{currentAccount.substring(0, 10) + "..."}</p></div>
                                            {validTokens.length > 0 ?
                                                <p>You qualify for a headcover</p>
                                                :
                                                <>
                                                    <p>You do not have any valid doubloons!</p>
                                                    <a href="headcoverdao.com/mint"><MediumButton text={"Mint a doubloon"} width={"50%"} /></a>
                                                </>
                                            }
                                        </>
                                        :    
                                            <MediumButton text={"Connect Wallet"} onClick={connectWalletHandler} width={"50%"} />}
                                    </div>
                            </CoverBox>
                                    
                                </Col>
                                <SizedBox height={"100px"} />

                                <div style={{width: "100%", margin: "auto"}}>
                                    <Col>
                                    <Row><CoverBox><img src="/images/Blade.webp"/><MediumButton text={"Select Blade"} disabled={validTokens.length == 0} onClick={() => {postDB("Blade");}} width={"50%"}/></CoverBox></Row>
                                        <Row><CoverBox><img src="/images/Mallet.webp"/><MediumButton text={"Select Mallet"} disabled={validTokens.length == 0} onClick={() => {postDB("Mallet");}} width={"50%"} /></CoverBox></Row>
                                        
                                    </Col>
                                </div>
                                </> : <>
                                <Row>
                                <Title>Headcover Airdrop Claim</Title>
                                <h4>HeadcoverDAO is not doing a claim for an airdrop at this time</h4>
                                <a href="https://discord.gg/RjZxMywdJM"> <MediumButton text={"Check Discord for updates"} width={"80%"}/></a>
                            </Row>
                                </>}
</> : <>
<Title>Headcover Claimed!</Title>
<SizedBox height="50px"/>
<CoverBox>
  <h1>Thank you</h1>
  <h3>{currentAccount.substring(0, 10) + "..."}, your {type} headcover will be airdropped soon!</h3>
  <img src={"/images/" + type + ".webp"}/>
</CoverBox></>}
                        </Col>
                    </div>
                    </div>
                    </MobileView>
            </>
        )
}